.img{
  width: 148px;
  margin: 19px 45px 16px 45px;
}

.imghov{
  width: 50px;
  margin: 19px 0px;
}

.swal-over-all {
  z-index: 999999999;
}
